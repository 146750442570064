@mixin flex($display: flex, $dir: row, $align: center, $alignX: $align, $alignY: $align, $wrap:wrap) {
  display: $display;
  flex-wrap: $wrap;
  flex-direction: $dir;

  @if $dir == "row" {
    align-items: $alignY;
    justify-content: $alignX;
  } @else {
    justify-content: $alignY;
    align-items: $alignX;
  }
}

@mixin mqFrom($media: $landscape) {
  @media screen and (min-width: #{$media}) { @content; }
}
@mixin mqTo($media: $landscape) {
  @media screen and (max-width: #{$media}) { @content; }
}

@mixin circle($color:$color-text, $border:0,$borderColor:$color, $background:$btnColor, $radius:7rem) {
  border-radius: 50%;
  width: $radius;
  height: $radius;
  background: $background;
  color: $color;

  border:$border solid $borderColor;
  @include flex($dir:column, $wrap:nowrap);
  position: relative;
}

@mixin circleBtn($color:$color-text, $border:0, $background:$btnColor, $radius:$btnToggleSize) {
  @include circle($color:$color-text, $border:0, $background:$btnColor, $radius:$btnToggleSize);

  font-size: .8rem;
  @content;
  cursor: pointer;

  @if $border == 0 {
    // box-shadow: 0 2px 8px -5px $color;
  }

  i {
    margin: 0;
    font-size: 1.4rem;
    line-height: .5;
    transition: transform .5s $bezier .2s;
    transform-origin: center;

    &:only-child {
      position: absolute;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      @include flex();

    }

    svg {
      margin: 0;
      backface-visibility: hidden;
    }
  }

  span {
    font-size: .5rem;

    + i {
      font-size: .8rem;

      svg {
        height: .8rem;
      }
    }
  }

  &:hover {
    // transition: all .3s ease;
    // background: $btnColorHover;
    // transform: scale(.95);


    i:only-child {
      transform: rotate(180deg);
      transition: transform .5s $bezier .2s;
    }
  }
}

@mixin absFill($top:0,$right:0,$bottom:0,$left:0) {
  position: absolute;
  top:$top;
  bottom:$bottom;
  right:$right;
  left:$left;
}

@mixin btn {
  padding: 2rem 3.5rem;
  font-size: 1.2rem;
  @include flex();
  display: inline-flex;
  line-height: 1;

  @include mqFrom($desktop){
    // padding: .5rem 1.5rem;
    font-size: 1.1rem;

  }
}

@mixin ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

//  @include aspect-ratio(16, 9);
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

@mixin maxWidth($width:$max-width){
  max-width: 100%;
  @include mqFrom($portrait){
    max-width: 90vw;
  }
  @include mqFrom($desktop){
    max-width: $max-width;
  }
  // @include mqFrom($xl){
  //   max-width: $max-width;
  // }
}

@mixin fontSize($size:1rem, $increase:0, $decrease:false){
  font-size: $size;
  @if $decrease != false {
    font-size: $size - ($size * $decrease);

    @include mqFrom($portrait){
      font-size: $size - ($size * $decrease/1.5);
    }
    @include mqFrom($desktop){
      font-size: $size - ($size * $decrease/2) ;
    }
    @include mqFrom($xl){
      font-size: $size;
    }
  }
  @else {
    @include mqFrom($portrait){
      font-size: $size + ($size * $increase);
    }
    @include mqFrom($desktop){
      font-size: $size + ($size * $increase * 1.5 );
    }
    @include mqFrom($xl){
      font-size: $size + ( $size * $increase * 2 );
    }
  }
}

@mixin nthTransition($animationDelay: 0.15s, $nthDelay: .1s, $elements: 10){

  @for $i from 0 to $elements+1 {
    &:nth-child(#{$i}) {
      $newDelay: $nthDelay* $i + $animationDelay;
      transition-delay: $newDelay;
    }
  }
}

@mixin fontTitle($size:$font-size--large, $weight:normal,$text:uppercase){
  font-size: $size;
  font-family: $font;
  font-weight: $weight;
  text-transform: $text;
  font-weight: 900;
}

@mixin fontBold($size:$font-size--large, $weight:900,$text:uppercase){
  font-family: $font-bold;
  font-weight: $weight;
  text-transform: $text;
}

@mixin fontSerif(){
  font-family: $font-serif;
  font-weight: 400;
  font-style: normal;
}
