

.columns {
  @include flex();
  position: relative;
  z-index: 1;

  display: block;

  &:after {
    content: "";
    display: table;
    width: 100%;
  }

  &__header {
    width: 100%;
    flex:none;
    @include flex($dir:col, $align:flex-start);
    padding: 0 $padding;
  }

  &__col {
    &--2 {
      @include mqFrom($portrait){
        width: 50%;
        float: left;

        &:nth-of-type(4n + 2),&:nth-of-type(4n + 3){
          float: right;
        }
        // &:nth-of-type(even) {
        //   float: right;
        //   clear: left;
        // }
      }
    }
    &--3 {
      @include mqFrom($portrait){
        width: calc(100%/3);
      }
    }
    &--4 {
      width: 50%;
      @include mqFrom($portrait){
        width: 25%;
      }
    }
  }
}

[class*="columns__col"]{
  @include flex();
  padding: $padding;
  align-self: flex-start;
}

.align-left {
  justify-content: flex-start;
  margin-right: auto;
}
