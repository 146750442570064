@import 'base.scss';

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  box-shadow: 0;
  outline: none;

  &:not([type="checkbox"]){
    font-size: $font-size;
    border:1px solid #d3d3d3;
    padding: .75rem 1rem ;
    border-radius:5px;
  }

  &[type="checkbox"]{

  }
}

.checkbox-wrapper {
  position: relative;
  width: 100%;

  label {
    @include flex($align:flex-start, $wrap:nowrap);
    width: 100%;
    font-size: .8rem;


    &:before  {
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 50%;
      background: #fff;
      content: "";
      display: flex;
      margin-right: .5rem;
      padding: .2rem;
      line-height: 0.8;
      flex:none;
      // position: absolute;
      left: 0;
      color: $blue;
      font-size: 1rem;
    }
    &:before {
      border: 1px solid;
    }
    // &:after {
    //   content: "✓";
    // }
  }

  input {
    flex:none;
    &:checked {
      background: red;

      + label {

        &:before {
          content: "✓";
        }
      }
    }
  }
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  box-shadow: 0;
  outline: none;
  margin-top:3rem;
}

.form {
  @include flex($dir:column);

  p {
    width: 100%;
  }

  form {
    @include flex($dir:column, $wrap:nowrap);

    input, .flex-row {
      width: 100%;
      flex:1;
    }

    .flex-row {
      input {
        &:first-child {
          margin-left: 0;
          margin-right: 0;
          max-width: 100px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  input {
    margin:0 .5rem .5rem;
    margin:0 1.0rem 1.0rem;
  }
}
