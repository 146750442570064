@import "base.scss";

.sponsors {
  @include flex($dir:column);
  text-align: center;
  padding: $margin 0;

  header {
    padding: $padding 0 0;
  }

  &__list {
    @include flex();
    @include ul-reset;

    li {
      // max-width: 200px;
      padding: $padding $margin;
      @include flex();

      a {
        position: relative;
        z-index: 7;
      }

      img {
        max-width: 100%;
        max-width: 200px;
        width: 100%;
      }

      @include mqTo($portrait){
        width: 50%;

        img {
          max-width: 120px;
          max-height: 90px;
        }
      }
    }
  }

}
