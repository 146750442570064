
//Breakpoints
$portrait:768px;
$landscape:1024px;
$desktop:1100px;
$xl:1350px;


// Colors general
$blue:#222549;
$blue--light:#83a9bf;
$blue--dark:#152b3f;
$brown: #8c8179;
$beige: #b4aca5;
$black: #222222;
$gray: #d9dad6;
$pink: #EBDADA;
$red: #f45932;
$yellow: #FCFF76;
$yellow: #fdff82;
$white: #f7f7f7;

// Color variables
$color-primary: rgba(150,150,250,1);
$color-secondary: green;
$color-bg: #f7f7f7;
$color-bg--dark: $blue--dark;
$color-bg--menu: rgba(#fff,1);

// $color-bg--article: linear-gradient(125deg, $beige,$white);
$color-text: $black;
$color-link: rgba(150,150,250,1);
$color-link--menu: #fff;
$color-link--menu: $blue;


// Font styles
$font-size:16px;
$font: "Helvetica Neue", "Helvetica", sans-serif;
$font: "sofia-pro", "Helvetica", sans-serif;




$line-height:1.5;
$line-height-heading:1.0;
$font-size--mini:.85rem;
$font-size--mini:.9rem;
$font-size--small:.95rem;
$font-size--normal:1.5rem;
$font-size--medium:1.8rem;
$font-size--large:4rem;
$font-size--h2:2.75rem;


// Transitions
$bezier: cubic-bezier(.51,.14,.29,1);


// Distances
$gutter:3rem;
$padding: $gutter/2;
$blockPadding: $gutter*3;
$margin: $gutter;
$margin-text: $gutter/2;


// Buttons
$btnToggleSize:2.7rem;
$btnColor:rgba(255,255,255,1);
$btnColor:$blue--dark;
$btnColor:$black;
$btnColorHover:rgba(255,255,255,0.3);
$btnColorHover:rgba($blue--dark,0.85);


// Max widths
$max-width:1100px;
$max-width-inner:900px;
$max-width-text:650px;
$max-width-text:750px;
$max-width-header:65vw;
$max-width-article:750px;

// General Sizes
$border-size:4px;
$line-size:1px;
