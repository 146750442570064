@import 'base.scss';
.page-footer {
  header {
    @include flex();
  }

  nav {
    margin-right: $margin;

    a {
      position: relative;
      z-index: 8;
    }
  }

  h3 {
    font-size: $font-size--medium;
    font-weight: normal;
    margin-bottom: $margin;
  }

  h4 {
    // font-weight: normal;
    margin-bottom: .2rem;

  }

  .logo-wrapper {
    margin: $margin auto;

    @include mqFrom($portrait){
      margin: -10vw 0 -5vw;
    }
  }

  .logo {
    max-width: 40vw;
    margin-left: auto;
    margin-right: auto;
    // @extend .rotateY;
  }
}
