@import "base.scss";

.quote {
  font-size: $font-size--medium;
  max-width: $max-width-inner;
  max-width: $max-width-text;
  text-align: center;
  @include flex($alignX:flex-end);
  @include fontSize($size: $font-size--normal,$decrease:.25);

  padding-left: $padding;
  padding-right: $padding;
  position: relative;
  z-index: 1;

  &.inner.block {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include mqFrom($portrait){
      padding-top: 11rem;
      padding-bottom: 11rem;
    }
  }

  span {
    width: 100%;
  }

  p {
    font-size: inherit;
  }
  @include mqFrom($landscape){
    padding-left: 0;
    padding-right: 0;
  }

  &-bg {
    position: relative;

    &:before, &:after {
      @include flex();
      content: attr(data-quote);
      position: absolute;
      height: 50%;
      width: 200%;
      font-size: 6vw;
      color: rgba($red,.1);
      z-index: 0;

      animation-duration: 20s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      text-shadow: 200vw 0 0 rgba($red,.1);
    }

    &:before {
      top:0;
      animation-name: scroll-left;

    }
    &:after {
      bottom:0;
      animation-name: scroll-right;

    }
  }
}

.author {
  text-align: right;
  align-self: flex-end;

  &:before {
    content: "–";
  }
}

.line-bg {
  @include absFill;
  overflow: hidden;
  @include flex($dir:column);
  padding: 4rem 0;
  //
  // &:before, &:after {
  //   position: absolute;
  //   height: 92px;
  //   width: 300px;
  //   background: red;
  //   top:0;
  //   left:0;
  //   right: 0;
  //   content: "";
  //   margin: auto;
  // }
  //
  // &:after {
  //   top:auto;
  //   bottom: 0;
  // }

  &__top, &__bottom {
    @include flex();
    // position: absolute;
    height: 40%;
    height: 45%;
    width: 200%;
    color: rgba(#fff,.15);
    @include fontTitle;
    @include fontTitle(5.5rem);
    text-transform: none;
    line-height: 1;
    // font-size: 6vw;
    margin: auto;
    z-index: 0;
    animation-duration: 160s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    // text-shadow: 200vw 0 0 rgba($red,.1);

    span {
      white-space: nowrap;
      text-align: center;
      // animation-duration: 20s;
      // animation-fill-mode: both;
      // animation-iteration-count: infinite;
      // animation-timing-function: linear;
      // text-shadow: 200vw 0 0 rgba($red,.1);
    }
  }

  &__top {
    // top:0;
    animation-name: scroll-left;

  }
  &__bottom {
    // bottom:0;
    animation-name: scroll-right;
    margin-bottom: 1rem;
  }
}

.read-in-line {
  // transform: scale(.98);
  opacity: 0;
  transition: all .3s ease;

  .scroll-point--active & {
    opacity: 1;
    transform: none;
    transition: all 1.3s ease;

    @include nthTransition($nthDelay:1s);

  }
}
