@import "mixins";
@import "variables";



.scroll-point {


  &--inner {
    .pop-in {
      animation-name: show;
    }
    .ease-in {
      animation-name: easeIn;
      animation-duration: 2.3s !important;
    }

    .img-in {
      // animation-name: popIn;
      // animation-name: rubberBand;
    }

    [class*="-in"]{
      animation-fill-mode: both;
      animation-play-state: paused;
      animation-duration: 1.3s;
      animation-iteration-count: 1;
      animation-timing-function: $bezier;
    }

    &.scroll-point--active {
      [class*="-in"]{
        animation-delay: .6s;
        animation-play-state: running;
      }
    }
  }

  &:not(.freeze){
    // > * {
    //   transition: all .6s $bezier 0s;
    //
    //   transform: scale(.9);
    //   transform: scale(1.1);
    // }
  }

  [class*="columns__col"], [class*="fade-nth-in"], .fade-in {
    transition: all .2s ease 0s;
    transform: translateY(40px);
    opacity: .0;
  }

  .images & {
    figure {
      transition: all 2s ease 0s;
      transition: transform 1s ease, opacity 1.6s ease .2s, filter 1s ease 1.8s;

      // transform: translateY(15px) scale(.97);
      transform: scale(.97);
      opacity: .3;
      // filter: saturate(0%);
    }
  }

  &--active {



    &:not(.scroll-point--inner){
      [class*="columns__col"], [class*="fade-nth-in"], .fade-in {
        transition: transform 1.6s ease, opacity 1.8s ease 0s;
        transform: none;
        opacity: 1;
        @include nthTransition($animationDelay:.3s,$nthDelay:.1s);
      }
    }

    &:not(.freeze){

      > * {
        transform: none;
        transition: all 1.0s $bezier .3s;
      }
    }

    // [class*="columns__col"], [class*="fade-nth-in"], .fade-in {
    //   transition: transform 1.6s ease, opacity 1.8s ease 0s;
    //   transform: none;
    //   opacity: 1;
    //   @include nthTransition($animationDelay:.3s,$nthDelay:.1s);
    // }

    .fade-nth-in--row {
      @include nthTransition($nthDelay:.1s);
    }

    .images & {
      figure {
        @include nthTransition($nthDelay:.15s, $elements:2);
        filter: none;
        transform: none;
        opacity: 1;
      }
    }
  }
}
