
@keyframes hoverBtn {
  0% {
    background-size: contain;
    background-position: 32% center;
  }
  100% {
    background-size: contain;
    background-position: 1000% center;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 2.4s infinite ease-in-out;
}

@keyframes rotateY {
  0% {
    transform:rotateY(0);
  }
  60%,100% {
    transform:rotateY(360deg) rotateZ(360deg);
  }
}

.rotateY {
  animation: rotateY 5.4s infinite ease-in-out;
}


@keyframes setFree {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  0%,100% {
    // transform: none;
    opacity: 1;
    margin: 0;
  }
  25% {
    margin-left: -50%;
    // width: 50vw;
  }
  75% {
    margin-top: -10%;
  }
  50% {
    transform: rotate(720deg);
    width: 40vw;
  }
}

// @keyframes setLetterFree {
//   0%,100% {
//     transform: none;
//     margin: 0;
//     opacity: 1;
//   }
//   25% {
//     margin-left: -150%;
//     opacity: 1;
//   }
//   75% {
//     margin-top: -100%;
//     margin-left: 50%;
//     opacity: 1;
//   }
//   50% {
//     transform: rotate(180deg);
//     opacity: 1;
//   }
// }

@keyframes setLetterFree {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
  0%,100% {
    // transform: none;
    margin: 0;
    // opacity: 1;
  }
  25%,75% {
    margin-left:-50%;
    opacity: 1;
  }
  75% {
    margin-top: -100%;
    opacity: 1;
  }
  50% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes setFree {
  0% {
    transform: rotate(0deg) translateX(100%);
    opacity: 1;
  }
  100% {
    transform: rotate(-360deg) translateX(100%);
    opacity: 1;
  }

  50% {
    transform: rotate(-180deg) translateX(-100%);
    opacity: 1;
  }
}

@keyframes setLetterFree {
  0% {
    transform: rotate(0deg) translateX(100%);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg) translateX(100%);
    opacity: 1;
  }

  50% {
    transform: rotate(180deg) translateX(-200%);
    opacity: 1;
  }
}


@keyframes popIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes speedIn {
  0% {
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform-origin: 50% 0%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scaleX(1) scaleY(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes speedInFromTop {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
}

@keyframes show {

  from,
   20%,
   40%,
   60%,
   80%,
   to {
     animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
   }

  from {
    opacity: 0;
    pointer-events: none;
  }
  0% {
    transform: scale(0);
  }
  35% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
  to {
    opacity: 1;
    pointer-events: all
  }
}

@keyframes easeIn {
  from,
   20%,
   40%,
   60%,
   80%,
   to {
     animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
   }

  from {
    opacity: 0;
    pointer-events: none;
  }
  0% {
    transform: scale(0.8);
  }
  35% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
  to {
    opacity: 1;
    pointer-events: all
  }
}

@keyframes scroll-left {
    0%, 100% {
      transform: translateX(00%);
    }
    50% {
      transform: translateX(-50%);
    }
}
@keyframes scroll-right {
    0%, 100% {
      transform: translateX(-50%);
    }
    50% {
      transform: translateX(00%);
    }
}
