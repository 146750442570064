@import 'base.scss';



.btn, [class*="btn-"] {
  @include btn;
  background: $btnColor;
  background: url('../img/wave_.svg') repeat-x;
  color: #fff;
  animation: hoverBtn 12s infinite linear forwards;
  animation-play-state: paused;
  cursor: pointer;
  font-weight: bold;
  z-index: 8;

  .bg--blue & {
    background: url('../img/wave_white.svg') repeat-x;
    color: $blue;
  }

  &:hover {
    animation-play-state: running;
  }
}


.btn--icon i, .btn--icon  i {
    margin-right: 5px;
}



.btn--small {
  padding:var(--btnSmallPadding);
  font-size: var(--fontMedium);
  width: auto;
}

.btn--mini {
  padding:var(--btnMiniPadding);
  font-size: var(--fontSmall);
  width: auto;
}


.btn:hover {
  transform: scale(1.02);
}

.btn.clicked {
  transform: scale(.95);
  transition: all .1s ease-in-out;

}

.btn.mouse-down {
  animation: btnShiver .6s infinite;
}


.btn + .btn {
  /* margin-top: 15px; */
}

.disabled {
  pointer-events: none !important;
  opacity: .5 !important;
}

@keyframes btnShiver {
  0%,100% {
    transform: rotate(0) scale(1);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: rotate(-2deg);
  }
  20%, 40%, 60%, 80% {
    transform: rotate(2deg);
  }
}
