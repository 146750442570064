@import "base.scss";

.photographer {
  display: block;
  padding: 0 $padding;
  width: 100%;
  text-align: right;
  margin-top: -1rem;
}

.images {
  margin-left: $padding/2;
  margin-right: $padding/2;

  @include mqFrom($portrait){
    margin-left: -$padding/2;
    margin-right: -$padding/2;
  }

  figure {
    margin: 0;
    max-width: 100%;
    // @include responsive-ratio(16,9,true);
    img {
      padding: $padding/2;
      max-width: 100%;
      object-fit: cover;
      height: 30vh;
      z-index:1;
      position: relative;

      @include mqFrom($portrait){
        height: 68vh;
        padding: $padding;

      }
    }

    &:only-child {
      img {
        height: auto;
      }
    }
  }

  .flex-row {
    position: relative;

    flex-wrap: nowrap;

    figure {
      // flex: 1;
    }
  }
}
