// @import "variables";
@import "base";

.where {
  position: relative;
  padding: 10vh 0;
  .content {
    z-index: 10;
    position: relative;

    .coords {
      position: absolute;
      color: $yellow;
      @include fontTitle();
      font-size: 1.5rem;
      @include mqFrom($portrait){

        font-size: $font-size--medium;
      }
    }
  }

  .map {
    max-height: 70vh;

    img {
      width: auto;
      height: 70vh;
      object-fit: contain;
    }
  }

  .animated-letters {
    left: 60vw;
    width: 40vw;
    z-index: 2;
    height: 80%;
    img {
      max-width: 10vw;
      max-height: 10vw;

      @include mqFrom($portrait){
        max-width: 4vw;
        max-height: 4vw;
      }
    }
  }
}
