@import "base.scss";

.media-wrapper {
  @include responsive-ratio(16,9, $pseudo:true);
  position: relative;
}

.embedd {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  vertical-align: bottom;
  
  // @mixin responsive-ratio($x,$y, $pseudo: false) {

  // @include responsive-ratio(16,9, $pseudo:true);
}
