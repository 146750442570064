

$animationDelay: 0.15s;
$nthDelay: 1s;


$elements: 5;

.animated-letters {
  // background: rgba($yellow,.5);
  width: 100%;
  height: 300px;
  position: absolute;
  bottom: 0;
  right: 0;
  @include flex($align:flex-end);
  @include flex($align:space-between);

  width: 100%;

  @include mqTo($portrait - 1){
    display: none;
    // width: 400px;
    height: 200px;
  }



  .letters {
    // opacity: 0;
    width: 10vw;
    transform: none;
    margin: 10% 0 0;
    animation: setFree 20s infinite ease forwards;
    @include flex();
    @include flex($dir:column,$align:space-between);


    transition: all 1s ease;

    @for $i from 0 to $elements+1 {
      &:nth-child(#{$i}) {
        $newDelay: $nthDelay* $i*10 + $animationDelay;
        animation-duration: $newDelay;
      }
    }

    @include mqFrom($portrait){
      width: 20vw;

    }
  }

  .letter {
    // opacity: 0;
    transform: none;
    margin: 0;
    animation: setLetterFree 15s infinite ease forwards;
    display: flex;
    transition: all 1s ease;



    &:nth-child(2){
      // margin:-50% -50% auto auto;
      align-self: flex-end;
    }

    @for $i from 0 to $elements+1 {
      &:nth-child(#{$i}) {
        $newDelay: $nthDelay* $i*10 + $animationDelay;
        animation-duration: $newDelay;
      }
    }
  }
}
