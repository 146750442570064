@import "base.scss";

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;

}

html,body {
  font-size: $font-size;
  font-family: $font;


  @include mqTo($portrait){
    overflow-x: hidden;
    overflow-y: visible;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

h1, h2, h3, h4, h5 {
  margin-top:0;
  margin-bottom:.5rem;
  width: 100%;
  line-height: 1.2;
}

h2 {
  font-size: $font-size--h2;
}
h3 {
  font-size: $font-size--normal;
}

p {
  line-height: 1.3;
  margin-top:0;
  margin-bottom:.5rem;
  font-size: $font-size--normal;
  @include fontSize($size: $font-size--normal, $decrease:.25);
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

[href*="#"]{
  cursor: pointer;
}

.large-text {
  font-size: $font-size--medium;

  @include fontSize($size: $font-size--medium,$decrease:.25);

  line-height: 1.1;

  p {
    line-height: inherit;
  }
}

.title {
  font-size: $font-size--normal;

  &--large {
    @include fontSize($size: 2rem,$increase:.75);
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1.0;
  }

  &--hero {
    @include fontSize($size: 3rem,$decrease:.25);
    text-transform: uppercase;
    font-weight: 100;
    line-height: 1.0;

    @include mqTo($portrait - 1){
      max-width: 260px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--medium {
    font-size: $font-size--medium;
  }
  &--small {
    font-size: $font-size--small;
  }
}


.container, .inner {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.container {
  // max-width: $max-width;
  @include maxWidth($max-width);
}

.inner {
  // max-width: $max-width-inner;
  padding: $padding;

}

.flex{
  &-row {
    @include flex();
  }
  &-col {
    @include flex($dir:column, $alignX:flex-start);
  }

  &--spread {
    justify-content: space-between;
  }
  &--stretch {
    justify-content: stretch;
  }
  &--start {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.block {
  padding: $margin 0;
  width: 100%;

  @include mqFrom($portrait){
    padding: $blockPadding 0;
  }
}

.svg-logo {
  display: block;
  width: 4rem;
  height: auto;
}

.color- {

  &red {
    color:$red;
    fill:$red;
  }
  &white {
    color:white;
    fill:white;
  }
}

.bg {

  &--blue {
    background: $blue;
    color: #fff;
  }
  &--pink {
    background: $pink;
    color: #000;
  }
  &--yellow {
    background: $yellow;
  }

  &-slice {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;

    &:before {
      content: "";
      height: 100%;
      width: 100%;
      background: $yellow;
      display: block;
      position: absolute;
      right: 0;
      z-index: -1;
      transform: rotate(0deg) translate(105%,0);
      transition: all 2s $bezier .3s;
      .scroll-point--active & {
        transform: rotate(-15deg) translate(100%,0);

      }
    }
  }

  &-vow {
    position: relative;
    background: $yellow;
    overflow: hidden;
    &:before {
      content: "";
      height: 180%;
      width: 110%;
      background: url("../img/VOW_gray.svg")no-repeat;
      background-size: cover;
      background-position: 100%;
      display: block;
      position: absolute;
      right: 0;
      top:0;
      z-index: 0;
      transform: rotate(0deg) translate(105%,0);
      transition: all 2s $bezier .3s;
      .scroll-point--active & {
        transform: rotate(0deg) translate(0%,-5%);
        background-position: -34%;
        transition: all 4s $bezier .6s;


      }
    }

    &.scroll-point--active {
      &:before {
        // transform: rotate(0deg) translate(0%,0);
      }
    }
  }
}

.abs-bg {
  position: relative;
  overflow: hidden;
  width: 100%;

  // &:not(.hero){
  //   .bg-img {
  //     width: auto;
  //     min-width: 0;
  //     height: 100%;
  //     // position: relative;
  //
  //
  //   }
  // }
}



.fill {
  width: 100%;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;

  &-bottom {
    bottom: 0;

    @include mqFrom($portrait){
      position: absolute;

    }

    &.block {
      padding: $padding*2;
    }
  }
}

.video-format {
  &:before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top:calc( ( 9 / 16 ) * 100%) ;
  }
}

.bg-img {
  // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  z-index: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;

  .abs-img & {
    position: absolute;
  }
}

.page {
  transition: all .6s $bezier;
  transition-delay: .2s;

  .menu-open + & {
    transform: scale(.8);
    transform: scale(1.2);
    filter: blur(100px);
    transition-delay: 0s;
  }
}

main {
  opacity: 1;
  transition: all 1s ease 1s;

  &.loading {
    opacity: 0;
    transition: all 1s ease 1s;

  }
  > * {

    max-width: 100%;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

[class*="loader"] {
  @include absFill;
  position: fixed;
  background: $red;
  z-index: 100;

  &:after, &:before {
      transition: all 1s ease;
      position: fixed;
      pointer-events: none;
      content: "";
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      @include flex();
      z-index: 9999;
      color: #fff;
    }

    &:before {
      content: url('../img/VOW_vit.svg');
      background-position: center;
      width: 50vw;
      height: 50vw;
      display: block;
      margin: auto;
    }

    &:after {
      width: 75px;
      height: 75px;
      border-top:4px solid #fff;
      border-left:4px solid #fff;
      border-bottom:4px solid #fff;
      border-right:4px solid transparent;
      border-radius: 50%;
      background: none;
      margin: auto;
      top:20vw;
      animation: loader 1s infinite linear;
    }
  }

.loader {
  transition: all 1s ease;
  &--loading {
    &:after, &:before {
      opacity: 1;
    }
  }
  &--loaded {
    background: none;
    pointer-events: none;

    &:after, &:before {
      opacity: 0;
    }
  }
}

.hide,.hidden {
  display: none !important;
}
