@import "base.scss";


.hero {
  @include flex($dir:column);
  color: #fff;

  &-menu {

    z-index: 10;
    padding: $padding;
    top:0;
    position: absolute;

    a {
      padding: .3rem $padding;
    }

    @include mqTo($portrait - 1){
      display: none;
    }
  }

  h1 span {
    display: block;
  }

  @include mqFrom($portrait){
    min-height: 100vh;

    // @include flex($alignY:stretch);
  }

  .animated-letters {
    z-index: 2;
  }

  &-image {
    position: relative;
    width: 100%;

    position: absolute;
    bottom: 0;
    top:0;
    left: 0;
    pointer-events: none;

    img {
      position: relative;
      z-index: 0;
    }

    &--blue {

      &:after {
        background: rgba($blue,.45);
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        z-index: 1;
        background-blend-mode: multiply;

      }
    }

    @include mqFrom($portrait){
      position: absolute;
      bottom: 0;
      top:0;
      left: 0;
      &--half {
        position: relative;

        width: 50%;
        flex:1;
      }
    }
  }

  // [class*="text-content"]{
  //   @include mqFrom($portrait){
  //     flex:1;
  //     width: auto;
  //
  //   }
  // }

  .logo-wrapper {
    margin-bottom: $padding;
    max-width: 60vw;
    margin: auto;

    @include mqFrom($portrait){
      max-width: 40vw;
    }
  }

  .text-content {
    z-index: 2;
    padding: $blockPadding 0 $blockPadding;
    text-align: center;
    width: 100%;
    @include mqFrom($portrait){
      // padding: $blockPadding 0 $padding;

    }

    .btn {
      margin-top:$margin;
    }

    &--block {
      background: $pink;
      @include mqFrom($portrait){
        width: 50%;
        flex:1;
        order:-1;
      }
    }
  }
}

@keyframes changeText {
  from, 60%, to {
    // filter: blur(20px);
    transform: scale(.9);
    transition: all .6s $bezier;
    opacity: 0;

  }
  10%,50% {
    transform: none;
    filter: none;
    opacity: 1;
  }
}

@keyframes changeText {
  from, 60%, to {
    filter: blur(20px);
    transform: scale(.9);
    transition: all .6s $bezier;
    opacity: 0;

  }
  from {
    transform: translateY(3rem);
  }
  10%,50% {
    transform: none;
    filter: none;
    opacity: 1;
  }
  60%, to {
    transform: translateY(-3rem);
  }
}

// @keyframes changeText {
//   from
//   {
//     transform: translatey(1.5rem)  scale(1)rotateX(-80deg);
//     // filter: blur(50px);
//     opacity: 0;
//     z-index: 2;
//   }
//   10%,50% {
//     transform: translatey(0rem) scale(1);
//     filter: none;
//     opacity: 1;
//     z-index: 1;
//   }
//   60%, to {
//     transform: translatey(-1.5rem) scale(1) rotateX(80deg);
//     // filter: blur(50px);
//     opacity: 0;
//     z-index: 0;
//
//   }
// }

$dur:10s;

.change-text {
  position: relative;

  &__content {
    animation: changeText $dur infinite both $bezier;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    margin: auto;
    animation-delay: $dur/2;

    &:first-child {
      position: relative;
      animation-delay: 0s;

    }
  }
}
