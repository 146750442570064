@import 'base.scss';

.registration {
  position: relative;
  @include flex($dir:column);
  background: $yellow;

  padding: 7rem 0 0rem;

  figure {
    max-height: 80vh;
    width: 30vw;

    img {
      max-height: 100%;
      max-width: 100%;
    }

    @include mqFrom($portrait){
      // Ta bort bortkommenterat när anmälan öppnar
      // transform: translate(0%, 6rem);
      img {
        max-height: 60vh;

      }
    }
  }
}

.content, .form-block, .form {
  transition: all 1s $bezier;
}

.form-wrapper {
  max-width: 400px;

}

.form {
  // background: $blue;
  width: 100%;
  padding: $padding;
  // @include flex();
  max-width: 500px;

  flex:1;

  @include mqFrom($portrait){
    padding: $margin;
  }

  h1,h2,h3 {
    margin-bottom: $margin-text;
  }

  + figure {
    transform: translateX(-2vw);
  }

  form {
    transition: opacity .6s ease;
  }

  &.submitted form {
    opacity:0 ;
    max-height: 0;
  }

  &__info {

    @include mqFrom($portrait){
      height: 0;
      transform: translate(120%, -3.6rem);
    }
  }


}

.form-message {
  transition: all 1s ease .5s;

  &.hide {
    opacity: 0;
  }
}

.form-block {
  max-height: 0;
  padding: 0 $padding;
  overflow: hidden;

  @include mqFrom($portrait){
    padding: 0 $margin;
  }

  .form-open & {
    max-height: 1300px;
    padding: $padding;

    @include mqFrom($portrait){
      padding: $margin;
    }

    // form {
    //   transition: all 1s ease .5s;
    //   opacity: 1;
    //   transform: none;
    // }
  }
}

.content {
  flex:1;
  padding-left: $margin;
  padding-right: $margin;
  @include flex($dir:column);
  text-align: center;

  .btn {
    margin-top: $margin;
  }

  .form-open & {

  }
}

// .form {
//   max-width: 0;
//   backface-visibility: hidden;
//   flex:1;
//   transform-origin: right center;
//   transform: translateX(100%);
//   opacity: 0;
//   transition: opacity 1s ease .6s, max-width 1s $bezier;
//
//
//   .form-open & {
//     background: red;
//     max-width: 50vw;
//     transform:none;
//     opacity: 1;
//   }
// }
